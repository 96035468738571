<template>
  <v-navigation-drawer :class="['aScroll', visibleTextMenu ? 'is-expanded' : '']">
    <v-list>
      <template v-for="(item, index) in menuItems">
        <v-list-item
          v-if="!item.subItems ||(item.subItems && !item.subItems.length)"
          :key="`item-${index}`"
          :prepend-icon="item.icon"
          :title.attr="item.title"
          :to="localePath({'name': item.linkPath})"
          @click="visibleTextMenu = false"
        >
          <template #title>
            {{ item.title }}
          </template>
        </v-list-item>
        <v-list-group v-else :key="`group-${index}`">
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              :prepend-icon="item.icon"
              :title.attr="item.title"
            >
              <template #title>
                {{ item.title }}
              </template>
            </v-list-item>
          </template>
          <v-list-item
            v-for="(subItem, i) in item.subItems"
            :key="i"
            :title.attr="subItem.title"
            :prepend-icon="subItem.icon"
            :to="localePath({'name': subItem.linkPath})"
            @click="visibleTextMenu = false"
          >
            <template #title>
              {{ subItem.title }}
            </template>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
    <div
      v-if="menuItems.length"
      :class="['themeSwitch themeSwitch--menu', visibleTextMenu ? 'is-dirty' : '', 'text-center cursor-pointer']"
    >
      <div
        class="themeSwitch__item flex-center"
        @click="visibleTextMenu = !visibleTextMenu"
      >
        <v-icon icon="ct:expand" size="x-small" />
      </div>
      <div
        class="themeSwitch__item flex-center"
        @click="visibleTextMenu = !visibleTextMenu"
      >
        <v-icon icon="ct:expand" size="x-small" />
      </div>
      <div class="themeSwitch__slider" />
    </div>
  </v-navigation-drawer>
</template>
<script setup lang="ts">
import { mdiInvoiceTextMultipleOutline, mdiFolderMoveOutline } from "@mdi/js";

const localePath = useLocalePath();
const { t } = useI18n();
const authStore = useAuthStore();

type MenuItem = {
  title: string
  icon: string
  linkPath?: string
  subItems?: MenuItem[]
  permissions?: string[]
}

const menuItems = computed<MenuItem[]>(() => {
  const items = [
    { title: t("main_menu.buyout_list"), icon: "ct:dashboard", linkPath: "cashtec", permissions: ["can_view_buyouts"] },
    { title: t("main_menu.settings_bonus"), icon: "ct:gift", linkPath: "cashtec-setup-bonus", permissions: ["can_view_bonuses"] },
    { title: t("main_menu.blacklist"), icon: "ct:lock-alt", linkPath: "cashtec-blacklist", permissions: ["can_view_blacklist"] },
    { title: t("main_menu.logs"), icon: "ct:pencil", linkPath: "cashtec-logs", permissions: ["can_view_buyout_logs"] },
    { title: t("main_menu.payments"), icon: "ct:cash", linkPath: "cashtec-payments", permissions: ["can_view_payments"] },
    { title: t("main_menu.delivery_notes"), icon: "ct:paper", linkPath: "cashtec-delivery-notes", permissions: ["can_view_delivery_notes"] },
    { title: t("main_menu.chats"), icon: "ct:chat", linkPath: "cashtec-chats", permissions: ["can_view_chats"] },
    { title: t("main_menu.export"), icon: "ct:cloud", linkPath: "cashtec-export", permissions: ["can_view_exports"] },
    { title: t("main_menu.tools"), icon: "ct:network", linkPath: "cashtec-tools", permissions: ["can_view_tools"] },
    { title: t("main_menu.business_cases"), icon: "ct:basket", linkPath: "upgrade-business-cases", permissions: ["can_view_business_cases"] },
    { title: t("main_menu.business_case_issues"), icon: "up:triangle-exclamation", linkPath: "upgrade-business-case-issues", permissions: ["can_view_business_case_issues"] },
    { title: t("main_menu.invoices"), icon: mdiInvoiceTextMultipleOutline, linkPath: "upgrade-business-case-invoices", permissions: ["can_view_business_case_invoices"] },
    { title: t("main_menu.program_master_devices"), icon: "ct:phone", linkPath: "upgrade-program-master-devices", permissions: ["can_view_leasing_products"] },
    { title: t("main_menu.installments_variants"), icon: "ct:chart-line", linkPath: "upgrade-installments-variants", permissions: ["can_view_leasing_curves"] },
    { title: t("main_menu.stock_moves"), icon: mdiFolderMoveOutline, linkPath: "upgrade-stock-moves", permissions: ["can_view_stock_moves"] },
  ];
  return items.filter(item => (item?.permissions ?? []).every(perm => authStore.hasPermission(perm)));
});

const visibleTextMenu = ref<boolean>(false);
</script>
